import axios from "axios";
import React, { useState, useEffect } from 'react';
import { API_URL,API_URL_LIVE } from "../config/datasource";

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken;
  }
  
  function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
  }


const axiosHttp = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      'Accept': 'application/json',
    }
  });

  axiosHttp.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken();
      if (token) {
        // config.headers["x-access-token"] = `Bearer ${token}`;
        // config.headers["Authorization"]=token;
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  


/*
  axios.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : null;
      
      if (status === 401) {
        // Handle unauthorized access
      } else if (status === 404) {
        // Handle not found errors
      } else {
        // Handle other errors
      }
      
      return Promise.reject(error);
    }
  );
*/

if (false) {
  axiosHttp.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (err.response) {
        // Access Token was expired
        console.log("token expired")
        window.localStorage.removeItem('accessToken');
       window.localStorage.removeItem('refreshtoken');
        window.location.href = "/login";

        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          console.log("Inside1")
          try {
            
            const rs = await refreshToken();
            const { accessToken } = rs.data;
            window.localStorage.setItem("accessToken", accessToken);
            axiosHttp.defaults.headers.common["x-access-token"] = accessToken;
            //added
           // axiosHttp.defaults.headers.common["Authorization"] = accessToken;
  
            return axiosHttp(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
  
            return Promise.reject(_error);
          }
        }
  
        if (err.response.status === 403 && err.response.data) {
          console.log("Inside2")
          return Promise.reject(err.response.data);
        }
      }
  
      return Promise.reject(err);
    }
  );
}

  function signin() {
    return axiosHttp.post("/general/loginbranch", {
      username: "zkoder",
      password: "12345678",
    });
  }
  
  function refreshToken() {
    return axiosHttp.post("/general/loginbranch", {
      refreshToken: getLocalRefreshToken(),
    });
  }
  
  function getUserContent() {
    return axiosHttp.get("/test/user");
  }


export default axiosHttp;