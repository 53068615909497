import { create } from 'zustand';
import axios from "axios";
import api from '../service/api';


// const response = await axios.get('https://pokeapi.co/api/v2/pokemon')
// set({ products: await response.data.results })

export const useProductStore = create((set) => ({
    products: [],
    productcategory: [],
    favouriteproducts: [],
    quoteproducts: [],
    eachProduct: {},
    total: 0,

    getProducts: async () => {
        try {
            await api.generic('GET', '/general/products').then((response) => {
                set({ products: response.data })
                console.log("data set")
            })
                .catch((error) => {
                    console.log("ERRR1")
                })
        } catch (error) {
            console.log("ERRR2")
        }
    },
    getProductCategory: async () => {
        try {
            console.log("called")
            await api.generic('GET', '/general/productcategory')
                .then((response) => {
                    set({ productcategory: response.data })
                    console.log("data set")
                })
                .catch((error) => {
                    console.log(error)
                    console.log("ERRR1")
                })
        } catch (error) {
            console.log(error)
            console.log("ERRR2")
        }
    },
    getFavouriteProducts: async () => {
        try {
            console.log("called")
            //await api.generic('GET','/general/branchfavouritelist')      
            await api.generic('GET', '/general/rfqsall')
                .then((response) => {
                    set({ favouriteproducts: response.data })
                    console.log("data set")
                })
                .catch((error) => {
                    console.log("ERRR1")
                })
        } catch (error) {
            console.log("ERRR2")
        }
    },
    getQuoteProducts: async () => {
        try {
            console.log("called")
            //await api.generic('GET','/general/branchfavouritelist')      
            await api.generic('GET', '/general/rfqsallquotes')
                .then((response) => {
                    set({ quoteproducts: response.data })
                    console.log("data set")
                })
                .catch((error) => {
                    console.log("ERRR1")
                })
        } catch (error) {
            console.log("ERRR2")
        }
    },
    getRFQs: async () => {
        try {
            console.log("called")
            await api.generic('GET', '/general/rfqs')
                .then((response) => {
                    set({ favouriteproducts: response.data })
                    console.log("data set")
                })
                .catch((error) => {
                    console.log("ERRR1")
                })
        } catch (error) {
            console.log("ERRR2")
        }
    },
    getProduct: async (productURL) => {
        //api doesn't exist - future point of view
        const response = await axios.get(productURL)
        set({ eachProduct: await response.data })
    },
    reset: () => set({ total: 0, favouriteproducts: [] })
}));
